import React from 'react';
import ReactWebChat, { createStyleSet, createDirectLine, createStore, createCognitiveServicesSpeechServicesPonyfillFactory, StyleOptions } from 'botframework-webchat'
import './App.css';
import IberdrolaLogo from './images/iberdrola_logo.png';
import IberdrolaLogoSmall from './images/iberdrola_logo_small.png';
import AvatarImage from './images/avatar.png';
import AvatarLarge from './images/jga.png';
import MarkdownIt from "markdown-it";
import AdaptiveCardsStyleOptions from 'botframework-webchat/lib/adaptiveCards/AdaptiveCardsStyleOptions';
const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;
let noaudio = false

interface WebChatProps {

}

interface WebChatState {
  store?: any,
  directLine?: any,
  ponyfillFactory?: any,
  canStart: boolean,
  avatarSet: boolean,
  firstInteraction: boolean,
  styleOptions: any,
  lang: string,
  isDesktop: boolean
}

function getUrlVars() {
    var vars = {} as any;
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
        return value;
    });
    return vars;
}

var isSpeak = false;

class WebChat extends React.Component<WebChatProps, WebChatState> {
  private parser: any;

  constructor(props: WebChatProps) {
    super(props);
    var styleOptions = this.createStyleOptions();

    const handler = e => {
      this.setState({ isDesktop: e.matches })
    };
    window.matchMedia("(min-width: 768px)").addEventListener('change', handler);

    let lang = navigator.language || (navigator as any).userLanguage || "en-US";
    var qs = getUrlVars();
    if (qs["locale"] && qs["locale"].length > 0) {
      lang = qs["locale"]
    }
    if (qs["lang"] && qs["lang"].length > 0) {
      lang = qs["lang"]
    }
    if (qs["language"] && qs["language"].length > 0) {
      lang = qs["language"]
    }
    if (!lang)
      lang = "en-US"
    else if (lang.startsWith("en"))
      lang = "en-US"
    else if (lang.startsWith("es"))
      lang = "es-ES"
    //else if (lang.startsWith("pt"))
    //    lang = "pt-PT"
    else
      lang = "en-US"

    document.documentElement.lang = lang;

    if (qs["noaudio"] && qs["noaudio"].length > 0) {
      noaudio = true
    }

    const store = createStore(
      {},
      ({ dispatch }: any) => (next: any) => (action: any) => {
        console.log(action.type);
        if (action.type === 'WEB_CHAT/START_DICTATE') {
          isSpeak = true;
        }
        if ((action.type === 'WEB_CHAT/SUBMIT_SEND_BOX' || action.type === 'WEB_CHAT/SEND_MESSAGE' || action.type === 'WEB_CHAT/SEND_MESSAGE_BACK') && !this.state.avatarSet) {
          var styleSet = this.createStyleOptions({
            botAvatarImage: AvatarImage,
            avatarSize: 100
          });
          this.setState({
            avatarSet: true,
            styleOptions: styleOptions
          })
        }
        else if (action.type === 'WEB_CHAT/SUBMIT_SEND_BOX' || action.type === 'WEB_CHAT/SEND_MESSAGE') {
          try {
            if (!action.payload)
              action.payload = {};
            if (!action.payload.channelData)
              action.payload.channelData = {};
            action.payload.channelData.userAgent = navigator.userAgent;
          } catch { }
          try {
            action.payload.channelData.referer = window.location.href;
          } catch { }
          try {
            action.payload.channelData.isSpeak = isSpeak;
          } catch { }
          if (action.type === 'WEB_CHAT/SEND_MESSAGE') {
            isSpeak = false;
          }
        }
        else if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: {
                locale: lang
              }
            }
          });
        }
        return next(action);
      }
    );

    this.state = {
      store: store,
      ponyfillFactory: undefined,
      directLine: undefined,
      canStart: false,
      styleOptions: styleOptions,
      lang: lang,
      avatarSet: false,
      firstInteraction: false,
      isDesktop: window.matchMedia("(min-width: 768px)").matches
    }

    this.parser = new MarkdownIt({
      html: true, // desactivamos el uso de HTML dentro del markdown
      breaks: true, // transforma los saltos de línea a un <br />
      linkify: true, // detecta enlaces y los vuelve enlaces
      xhtmlOut: true, // devuelve XHTML válido (por ejemplo <br /> en vez de <br>)
      typographer: true, // reemplaza ciertas palabras para mejorar el texto
      langPrefix: "language-" // agrega una clase `language-[lang]` a los bloques de código
    });

    this.load();
  }

  private createStyleOptions(styleOptions?: any) {
    var opts = {
      botAvatarImage: AvatarImage,
      botAvatarInitials: 'AVA',
      botAvatarBackgroundColor: 'transparent',
      accent: '#00402A',
      primaryFont: 'IberPangea',
      monospaceFont: 'IberPangea',
      avatarSize: window.innerWidth > 965 ? 200 : 60,
      bubbleBackground: '#DCEBE1',
      bubbleBorderRadius: 10,
      bubbleBorderStyle: 'none',
      bubbleBorderWidth: 0,
      bubbleNubOffset: 20,
      bubbleNubSize: 10,
      timestampColor: "#000000",
      bubbleFromUserBackground: '#FFFFFF',
      bubbleFromUserBorderColor: '#A2BCB1',
      bubbleFromUserBorderRadius: 10,
      bubbleFromUserBorderWidth: 1,
      bubbleFromUserNubOffset: 20,
      bubbleFromUserNubSize: 10,
      microphoneButtonColorOnDictate: '#F6F6F6',
      sendBoxButtonColorOnDisabled: '#CCC',
      sendBoxButtonColor: '#FFF',
      sendBoxButtonColorOnFocus: '#FFF',
      sendBoxButtonColorOnHover: '#333',
      hideScrollToEndButton: true,
      hideUploadButton: true,
    } as StyleOptions & AdaptiveCardsStyleOptions;
    if (styleOptions)
      opts = Object.assign(opts, styleOptions);
    //var styleSet = createStyleSet(opts);
    return opts;
  }

  public async load() {
    var isWebRTCSupported: any = (navigator as any).getUserMedia ||
      (navigator as any).webkitGetUserMedia ||
      (navigator as any).mozGetUserMedia ||
      (navigator as any).msGetUserMedia ||
      (navigator as any).RTCPeerConnection;

    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      isWebRTCSupported = undefined;
    }

    var response = await fetch('/api/v1/config');
    var config = await response.json() as any;

    const directLine = createDirectLine({
      token: config.directLineAuthorizationToken
    });

    var ponyfillFactory = isIE || noaudio ? undefined : await createCognitiveServicesSpeechServicesPonyfillFactory({
      credentials: {
        authorizationToken: config.speechAuthorizationToken,
        region: 'westeurope'
      }
    });

    this.setState({
      directLine: directLine,
      ponyfillFactory: ponyfillFactory
    });

    setTimeout(() => {
      this.setState({ canStart: true })
      setTimeout(() => (document.getElementsByClassName("webchat__send-box-text-box__input")[0] as any).focus(), 1000);
    }, 2000);
  }

  public render() {
    var self = this;

    if (!this.state.canStart || !this.state.directLine) {
      return (
        <div style={{
          zIndex: 5555,
          height: "100%",
          width: "100%"
        }}>
          <img src={AvatarLarge} alt="AVA" style={{
            position: "absolute",
            width: '100%',
            maxWidth: 320,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto'
          }} />
        </div>
      )
    }

    return (<div>
      <div>
        <img src={AvatarLarge} alt='JGA' height={65} style={{marginBottom: 25, marginLeft: 25, marginTop: 25 }} />
      </div>

      <ReactWebChat
      /*style={{ display: this.state.canStart ? 'inline-block' : 'none' }}*/
      store={this.state.store as any}
      activityMiddleware={() => (next: any) => (card: any) => {
        if (this.state.canStart && card.activity.from.role == "user" && this.state.avatarSet && !this.state.firstInteraction) {
          var styleOptions = this.createStyleOptions({
            botAvatarImage: AvatarImage,
            avatarSize: 60
          });
          setTimeout(() => self.setState({ styleOptions: styleOptions, firstInteraction: true }), 50);
        }
        if (!this.state.canStart) {
          setTimeout(() => self.setState({ canStart: true }), 2050);
        }
        setTimeout(() => {
          var lis = document.querySelectorAll(".webchat__basic-transcript__activity") as any;
          if (lis.length > 0 && lis[lis.length - 1]) {
            lis[lis.length - 1].scrollIntoView();
          }
        }, 500);

        return next(card);
      }}
      styleOptions={this.state.styleOptions}
      locale={this.state.lang}
      /*showUploadButton={false}
      user={{ locale: this.state.lang }}
      language={this.state.lang}
      locale={this.state.lang}*/
      renderMarkdown={this.parser.render.bind(this.parser)}
      directLine={this.state.directLine}
      webSpeechPonyfillFactory={this.state.ponyfillFactory} />

      {this.state.lang.startsWith("en") && <div className="footer" aria-label="Disclaimer">
        <img src={this.state.isDesktop ? IberdrolaLogo : IberdrolaLogoSmall} alt="IBERDROLA" style={{ marginRight: 10 }} />
        <span>
          The Shareholder Office will continue to answer any questions via the toll-free number <a href="tel:900100019">900 100 019</a> and the email <a href="mailto:accionistas@iberdrola.com">accionistas@iberdrola.com</a>
        </span>
      </div>}
      {!this.state.lang.startsWith("en") && <div className="footer" aria-label="Aviso legal">
        <img src={this.state.isDesktop ? IberdrolaLogo : IberdrolaLogoSmall} alt="IBERDROLA" style={{ marginRight: 10 }} />
        <span>
          La Oficina del Accionista seguirá atendiendo cualquier consulta a través del teléfono gratuito <a href="tel:900100019">900 100 019</a> y del correo electrónico <a href="mailto:accionistas@iberdrola.com">accionistas@iberdrola.com</a>
        </span>
      </div>}
      </div>
    );
  }
}

export default WebChat;
